<template>
  <section id="popupElement"
    class="scrollable-popup"
    :style="{ 'max-height': `${popupHeight}px`}">
    <div class="mr-1">
      <div class="port-items">
        <!-- LAG header -->
        <div v-if="isLag"
          class="lag-header port-item lag-separator ">
          <!-- Description -->
          {{ $t('productNames.lagLong') }}:

          <!-- LAG port statuses -->
          <lazy-popover width="300"
            placement="top"
            :open-delay="500"
            trigger="hover">
            <div class="text-align-left fs-1-4rem">
              <p class="text-align-center">
                <strong>{{ statusInfo.lagMessage }}</strong>
              </p>
              <p><strong>{{ $t('ports.statuses') }}</strong></p>
              <div v-for="(status, index) in statusInfo.statuses"
                :key="index"
                class="flex-row-centered position-relative">
                <div class="service-status-icon"
                  :class="status.status" />
                <div class="ml-2-4">
                  {{ status.portName }}
                </div>
              </div>
            </div>

            <template #reference>
              <div class="service-status-icon position-unset mx-0-5"
                :class="statusInfo.lagStatus" />
            </template>
          </lazy-popover>

          <!-- LAG Speed -->
          {{ portLike._speed }}

          <!-- Percentage allocated -->
          <template v-if="portLike._capacity && portLike._capacity.percent >= 100">
            <strong>({{ $tc('ports.percent-allocated', portLike._capacity.percent, { percent: portLike._capacity.percent }) }})</strong>
          </template>
          <template v-else>
            ({{ $tc('ports.percent-allocated', portLike._capacity ? portLike._capacity.percent : 0, { percent: portLike._capacity ? portLike._capacity.percent : 0 }) }})
          </template>

          <!-- Diversity zone -->
          <div role="presentation"
            class="ml-1">
            <span v-if="showDesignDiversityZone"
              :title="$t(`services.${designDiversityZone}-zone`)">
              {{ $t('services.diversity-zone') }}
              <i class="far fa-dot-circle"
                :class="`diversity-color-${designDiversityZone}`"
                aria-hidden="true" />
              <template v-if="portLike.config.diverseFrom">
                {{ $t('ports.diverse-from', {port: getProductNameFromPortUid(portLike.config.diverseFrom)}) }}
              </template>
            </span>
            <span v-else-if="portLike.diversityZone"
              :title="$t(`services.${deployedDiversityZone}-zone`)">
              {{ $t('services.diversity-zone') }}
              <i class="far fa-dot-circle"
                :class="`diversity-color-${deployedDiversityZone}`"
                aria-hidden="true" />
            </span>
          </div>

          <!-- Right end buttons -->
          <div class="lag-buttons flex-row-centered ml-auto">
            <!-- Add connection -->
            <el-tooltip placement="top"
              :content="determineAddConnectionTooltipContent(true)"
              :open-delay="500">
              <div>
                <el-button :disabled="!canConnect"
                  class="add-connection"
                  name="addConnection"
                  :data-serviceid="portLike.productUid"
                  :data-testid="`addConnection-${portLike._subUid}`"
                  @click="trackedNavigation('add-connection', `/create-connection/${portLike.productUid}`)">
                  <div class="flex-row-centered flex-justify-center gap-0-5">
                    <div>
                      <i class="fal fa-plus-circle action"
                        aria-hidden="true" />
                    </div>
                    <div class="connection-text">
                      {{ $t('connections.connection') }}
                    </div>
                  </div>
                </el-button>
              </div>
            </el-tooltip>
            <!-- Edit added port on lag -->
            <el-tooltip v-if="addedLagPort"
              placement="top"
              :content="$t('ports.configure-details')"
              :open-delay="500">
              <div>
                <el-button name="showDetails"
                  :data-testid="`showDetails-${portLike._subUid}`"
                  @click="showDetails(addedLagPort)">
                  <div class="flex-row-centered flex-justify-center gap-0-5">
                    <div>
                      <i class="fal fa-cog action"
                        aria-hidden="true" />
                    </div>
                    <div class="connection-text">
                      {{ $t('productNames.lag') }}
                    </div>
                  </div>
                </el-button>
              </div>
            </el-tooltip>
            <!-- Add ports to the lag -->
            <el-tooltip v-else-if="!disabledFeatures.addPortToLag"
              placement="top"
              :content="$t(canAddPort ? 'ports.add-ports-to-lag' : 'ports.lag-full')"
              :open-delay="500">
              <div>
                <el-button :disabled="!canAddPort"
                  data-name="addPort"
                  :data-serviceid="portLike.productUid"
                  :data-testid="`addPort-${portLike._subUid}`"
                  @click="trackedNavigation('add-lag-port', `/add-port-to-lag/${portLike.aggregationId}/${portLike.lagId}`)">
                  <div class="flex-row-centered flex-justify-center gap-0-5">
                    <div>
                      <i class="fal fa-plus-circle action"
                        aria-hidden="true" />
                    </div>
                    <div class="connection-text">
                      {{ $t('productNames.ports') }}
                    </div>
                  </div>
                </el-button>
              </div>
            </el-tooltip>
          </div>
        </div>

        <div v-for="portItem in portItems"
          :key="portItem.productUid"
          v-loading="isLoading"
          class="port-item"
          :class="{'lag-separator' : isLag}">
          <!-- Service icon -->
          <div>
            <lazy-popover width="300"
              placement="top"
              :open-delay="500"
              trigger="hover">
              <div class="text-align-left fs-1-4rem">
                <p class="text-align-center">
                  <strong>{{ getStatusInfo(portItem).message }}</strong>
                </p>
              </div>

              <template #reference>
                <mu-mega-icon :icon="getServiceIcon(portItem)"
                  class="product-icon"
                  :class="[getStatusInfo(portItem).status, getWhitelabelClass(portItem)]"
                  :data-service-name="portItem.productName"
                  :data-status="portItem.provisioningStatus"
                  @click="showDetails(portItem)" />
              </template>
            </lazy-popover>
          </div>

          <!-- Main info about the port -->
          <div class="port-main-content">
            <!-- First line -->
            <div>
              {{ portItem.productName }}
              <span v-if="portItem.provisioningStatus !== G_PROVISIONING_LIVE"
                class="ml-0-5">({{ snakeToCapitalizedWords(portItem.provisioningStatus) }})</span>
              <span v-if="portItem.provisioningStatus === G_PROVISIONING_CONFIGURED && !isMve && !portItem.virtual && !disabledFeatures.downloadLoa">
                <el-tooltip :content="$t('ports.waiting')"
                  placement="top"
                  :open-delay="500">
                  <el-button type="text"
                    class="ml-1"
                    data-name="download-loa"
                    :data-testid="`downloadLoa-${portItem._subUid}`"
                    @click.stop="downloadLoa(portItem.productUid)">
                    <i class="fas fa-file-download"
                      aria-hidden="true" /> {{ $t('ports.download-loa') }}
                  </el-button>
                </el-tooltip>
              </span>
            </div>

            <!-- Second line -->
            <div class="details-line">
              <!-- MVE vendor information -->
              <template v-if="isMve">
                <span>{{ $t('ports.vendor') }}: {{ mveVendor }},
                  {{ $t('general.size') }}: {{ mveSizeText }}</span>
              </template>
              <template v-else>
                {{ $t(isMcr ? 'productNames.mcr' : 'productNames.port') }} {{ calculatePortSpeedText(portItem) }}
                <template v-if="portItem.lagPortCount">
                  {{ $tc('ports.count-port-lag', portItem.lagPortCount, {count:portItem.lagPortCount}) }}
                </template>
                <!-- Port allocation percentage  -->
                <template v-if="!isLag">
                  <template v-if="portItem._capacity && portItem._capacity.percent >= 100">
                    <strong>({{ $tc('ports.percent-allocated', portItem._capacity.percent, { percent: portItem._capacity.percent }) }})</strong>
                  </template>
                  <template v-else>
                    ({{ $tc('ports.percent-allocated', portItem._capacity ? portItem._capacity.percent : 0, { percent: portItem._capacity ? portItem._capacity.percent : 0 }) }})
                  </template>
                </template>
              </template>
            </div>

            <!-- Third line - location and diversity zone -->
            <div class="details-line">
              <template v-if="portItem._location && portItem._location.formatted">
                {{ portItem._location.formatted.long }}
              </template>
              <template v-else>
                {{ $t('general.unknown-location') }}
              </template>

              <template v-if="allowsPortDiversity">
                <span v-if="showDesignDiversityZone"
                  :title="$t(`services.${designDiversityZone}-zone`)">
                  -
                  {{ $t('services.diversity-zone') }}
                  <i class="far fa-dot-circle"
                    :class="`diversity-color-${designDiversityZone}`"
                    aria-hidden="true" />
                  <template v-if="portItem.config.diverseFrom">
                    {{ $t('ports.diverse-from', {port: getProductNameFromPortUid(portItem.config.diverseFrom)}) }}
                  </template>
                </span>
                <span v-else-if="portItem.diversityZone"
                  :title="$t(`services.${deployedDiversityZone}-zone`)">
                  -
                  {{ $t('services.diversity-zone') }}
                  <i class="far fa-dot-circle"
                    :class="`diversity-color-${deployedDiversityZone}`"
                    aria-hidden="true" />
                </span>
              </template>
            </div>

            <!-- Actions and notes -->
            <conflict-component :service="portItem"
              class="mb-0-5" />


            <!-- Action buttons line -->
            <div class="action-block">
              <!-- Show details -->
              <el-tooltip placement="top"
                :content="determineDetailsTooltipContent(portItem)"
                :open-delay="500">
                <el-button name="showDetails"
                  :data-testid="`showDetails-${portItem._subUid}`"
                  @click="showDetails(portItem)">
                  <i class="fal fa-cog action"
                    aria-hidden="true" />
                </el-button>
              </el-tooltip>

              <!-- MCR looking glass -->
              <el-tooltip v-if="isMcr && !serviceIsInDesign"
                placement="top"
                :content="$t('menu.mcr-looking-glass')"
                :open-delay="500">
                <el-button name="showMcrLookingGlass"
                  :data-testid="`showMcrLookingGlass-${portItem._subUid}`"
                  @click="trackedNavigation('looking-glass', `/tools/mcr-looking-glass/${portItem.productUid}`)">
                  <i class="fal fa-binoculars action"
                    aria-hidden="true" />
                </el-button>
              </el-tooltip>

              <!-- Delete -->
              <el-tooltip placement="top"
                :content="getDeleteButtonData(portItem).message"
                :open-delay="500">
                <div>
                  <el-button :disabled="!getDeleteButtonData(portItem).canDelete"
                    data-name="deleteService"
                    :data-testid="`deleteService-${portItem._subUid}`"
                    @click="cancelService(portItem)">
                    <i class="fal fa-trash-alt action"
                      aria-hidden="true" />
                  </el-button>
                </div>
              </el-tooltip>

              <!-- Locking -->
              <el-tooltip v-show="!portItem.locked && !portItem.adminLocked && showLock"
                placement="top"
                :content="determineLockTooltipContent(portItem)"
                :open-delay="500">
                <div>
                  <el-button v-loading="checkIfLoading(portItem)"
                    :disabled="!isAdmin || portItem.provisioningStatus !== G_PROVISIONING_LIVE"
                    name="lock"
                    :data-testid="`lockService-${portItem._subUid}`"
                    @click="lockPort(portItem)">
                    <i class="fal fa-unlock-alt action"
                      aria-hidden="true" />
                  </el-button>
                </div>
              </el-tooltip>
              <el-tooltip v-show="portItem.locked || portItem.adminLocked"
                placement="top"
                :content="determineLockTooltipContent(portItem)"
                :open-delay="500">
                <div>
                  <el-button v-loading="checkIfLoading(portItem)"
                    :disabled="!isAdmin || portItem.provisioningStatus !== G_PROVISIONING_LIVE || portItem.adminLocked"
                    name="unlock"
                    :data-testid="`unlockService-${portItem._subUid}`"
                    @click="unlockPort(portItem)">
                    <i class="fal fa-lock action"
                      :class="{adminLocked:portItem.adminLocked}"
                      aria-hidden="true" />
                  </el-button>
                </div>
              </el-tooltip>

              <!-- Uuid -->
              <el-tooltip v-if="!serviceIsInDesign && portItem._subUid"
                placement="right"
                :content="$t('services.identifier-copy')"
                :open-delay="500">
                <span class="service-identifier"
                  :data-testid="`serviceIdentifier-${portItem._subUid}`"
                  @click.stop="copyToClipboard(portItem._subUid)">#{{ portItem._subUid }}</span>
              </el-tooltip>
            </div>
          </div>
          <!-- Add Connection button -->
          <div v-if="!isLag">
            <el-tooltip placement="top"
              :content="determineAddConnectionTooltipContent()"
              :open-delay="500">
              <div>
                <el-button :disabled="!canConnect"
                  class="add-connection"
                  name="addConnection"
                  :data-serviceid="portItem.productUid"
                  :data-testid="`addConnection-${portItem._subUid}`"
                  @click="trackedNavigation('add-connection', `/create-connection/${portItem.productUid}`)">
                  <div class="flex-row-centered flex-justify-center gap-0-5">
                    <div>
                      <i class="fal fa-plus-circle action"
                        aria-hidden="true" />
                    </div>
                    <div class="connection-text">
                      {{ $t('connections.connection') }}
                    </div>
                  </div>
                </el-button>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>

      <div v-if="connections.length"
        class="connections">
        <canvas id="connectionsCanvas"
          width="40"
          height="10" />

        <div id="connectionStack"
          class="stack">
          <connection-on-port v-for="connection in connections"
            :key="connection.productUid"
            class="stack-item"
            :connection="connection"
            :port="portLike"
            @navigateToUid="navigateToUid"
            @navigateToIx="navigateToIx" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import sdk from '@megaport/api-sdk'

import LazyPopover from '@/components/ui-components/LazyPopover.vue'
import ConflictComponent from '@/components/services/Conflict.vue'
import ConnectionOnPort from './ConnectionOnPort.vue'

import { downloadFile } from '@/utils/downloadFile.js'
import captureSentryError from '@/utils/CaptureSentryError.js'

import {
  copyToClipboard,
  convertSpeed,
  snakeToCapitalizedWords,
  capitalizeFirstOnly,
  closeTooltipsAndNavigateToRoute,
  // slug,
} from '@/helpers.js'
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'

export default Vue.extend({
  components: {
    LazyPopover,
    ConflictComponent,
    ConnectionOnPort,
  },

  props: {
    productUid: {
      type: String,
      required: true,
    },
    // This doesn't seem to work if we set it up as a Provide on the component, so add it directly
    disabledFeatures: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      popupHeight: 0,
      isLoading: false,
      loadingUid: null,
    }
  },

  computed: {
    ...mapGetters({
      findPort: 'Services/findPort',
      myPorts: 'Services/myPorts',
      doesPortHaveUntaggedServices: 'Services/doesPortHaveUntaggedServices',
      companyUid: 'Company/companyUid',
      showLock: 'Services/showLock',
    }),
    ...mapGetters('Auth', ['hasAuth', 'isManagedAccount', 'isPartnerAccount']),
    portLike() {
      const foundPort = this.findPort(this.productUid)
      if (!foundPort) {
        // This can happen when the popup is displayed and the port is deleted from under it.
        // Return a dummy object that contains the basics to avoid rendering errors. The popup
        // will be closed in the watcher to avoid direct side effects in the computed property.
        return {
          associatedIxs: [],
          associatedVxcs: [],
        }
      }

      // If the port we found was a sublag port, we want to find its parent and use that as the basis for our display
      if (foundPort.aggregationId && !foundPort.lagPrimary) {
        return this.myPorts.find(port => port.aggregationId === foundPort.aggregationId && port.lagPrimary)
      }
      return foundPort
    },
    connections() {
      return [...this.portLike.associatedVxcs, ...this.portLike.associatedIxs]
    },
    isLag() {
      return this.portLike.aggregationId || this.portLike.lagPortCount
    },
    portItems() {
      if (this.isLag && this.portLike.aggregationId && this.portLike._subLags) {
        return [this.portLike, ...this.portLike._subLags]
      }
      return [this.portLike]
    },
    statusInfo() {
      // Initialize our basic information
      const statusInfo = {
        lagStatus: '', // Status for overall LAG
        lagMessage: '', // Message for overall LAG
        statuses: [], // Array of statuses for each port in the LAG (or just the port if not part of a LAG)
      }

      for (const currentPort of this.portItems) {
        const currentPortStatusInfo = {
          productUid: currentPort.productUid,
          portName: currentPort.productName,
          status: '',
          message: '',
        }

        if (currentPort.provisioningStatus !== this.G_PROVISIONING_LIVE) {
          currentPortStatusInfo.message = `${this.$t('services.provisioning-status')}: ${currentPort.provisioningStatus}`
        } else if (typeof currentPort.up !== 'boolean') {
          currentPortStatusInfo.status = 'partial-success'
          currentPortStatusInfo.message = this.$t('services.unknown-status')
        } else {
          currentPortStatusInfo.status = currentPort.up ? 'all-go' : 'all-fail'
          currentPortStatusInfo.message = currentPort.up ? this.$t('services.up') : this.$t('services.down')
        }

        statusInfo.statuses.push(currentPortStatusInfo)
      }

      // Check whether we have all the LAG statuses filled in
      // to determine the status of the primary port in the LAG
      if (this.portLike.lagPrimary) {
        let filledStatuses = 0
        let operational = false

        for (const currentStatusInfo of statusInfo.statuses) {
          if (currentStatusInfo.status.length) {
            filledStatuses++

            if (currentStatusInfo.status === 'all-go') {
              operational = true
            }
          }
        }

        // If we have all the LAG statuses filled in,
        // set the port's status info based on the port's operational status
        if (filledStatuses === statusInfo.statuses.length) {
          statusInfo.lagStatus = operational ? 'all-go' : 'all-fail'
          statusInfo.lagMessage = operational ? this.$t('ports.lag-up') : this.$t('ports.lag-down')
        }
      }

      return statusInfo
    },
    serviceIsInDesign() {
      return this.portLike.provisioningStatus === this.G_PROVISIONING_DESIGN
    },
    serviceIsLive() {
      return this.portLike.provisioningStatus === this.G_PROVISIONING_LIVE
    },
    allowsPortDiversity() {
      return this.isPort
    },
    showDesignDiversityZone() {
      return this.serviceIsInDesign && this.portLike.config?.diversityZone
    },
    designDiversityZone() {
      return this.portLike.config?.diversityZone
    },
    deployedDiversityZone() {
      return this.portLike.diversityZone
    },
    canConnect() {
      const portIsNewOrInDesignDeploy = [this.G_PROVISIONING_DESIGN_DEPLOY, this.G_PROVISIONING_NEW].includes(this.portLike.provisioningStatus)
      return (portIsNewOrInDesignDeploy || !this.portLike._location) ? false : !this.doesPortHaveUntaggedServices(this.portLike)
    },
    canAddPort() {
      // If we have already added a LAG port with potentially multiple ports, then we don't want
      // the add button displayed
      if (!this.portLike.lagPrimary || !this.portLike._subLags) {
        return false
      }
      for (const lag of this.portLike._subLags) {
        if (lag.lagPortCount) {
          return false
        }
      }
      return this.portLike._subLags.length < 7
    },
    addedLagPort() {
      if (this.portLike.lagPortCount) {
        return this.portLike
      }
      if (this.portLike._subLags) {
        for (const lag of this.portLike._subLags) {
          if (lag.lagPortCount) {
            return lag
          }
        }
      }
      return null
    },
    isMve() {
      return this.portLike.productType === this.G_PRODUCT_TYPE_MVE
    },
    isPort() {
      return this.portLike.productType === this.G_PRODUCT_TYPE_MEGAPORT
    },
    isMcr() {
      return this.portLike.productType === this.G_PRODUCT_TYPE_MCR2
    },
    mveVendor() {
      return this.portLike.vendor || this.portLike.vendorConfig?._vendor
    },
    mveSizeText() {
      return this.serviceIsInDesign ? this.portLike.vendorConfig?.mveLabel || capitalizeFirstOnly(this.portLike.vendorConfig?.productSize) : this.portLike.mveLabel
    },
    canPlaceOrder() {
      return this.hasAuth('place_order')
    },
    isAdmin() {
      return this.hasAuth('company_admin')
    },

  },

  watch: {
    portLike: {
      deep: true,
      handler(newVal) {
        if (!newVal.productUid) {
          this.$emit('close')
          return
        }
        this.drawCanvas()
      },
    },
  },

  mounted() {
    const mapElement = document.getElementById('mapbox')
    this.popupHeight = mapElement?.offsetHeight * 0.4 || 150

    this.$nextTick(() => {
      const popupElement = document.getElementById('popupElement')
      if (popupElement) {
        popupElement.scrollTop = 0
      }
      this.drawCanvas()
    })
  },

  methods: {
    copyToClipboard,
    snakeToCapitalizedWords,
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),
    ...mapMutations('Services', ['setLockedState']),
    async downloadLoa(productUid) {
      this.isLoading = true
      const url = `${sdk.instance.baseurl}/v2/product/${productUid}/loa`

      try {
        await downloadFile(url, { openInNewTab: true })
      } finally {
        this.isLoading = false
      }
    },
    drawCanvas() {
      const canvas = document.getElementById('connectionsCanvas')
      if (!canvas) {
        return
      }
      const connectionStack = document.getElementById('connectionStack')
      canvas.height = connectionStack.clientHeight

      const ctx = canvas.getContext('2d')
      ctx.lineWidth = 2
      ctx.strokeStyle = '#cccfd6'

      const parentPos = canvas.getBoundingClientRect()

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      const curveRadius = 10

      for (const element of document.getElementsByClassName('stack-item')) {
        ctx.beginPath()
        ctx.moveTo(20, 0)
        const childPos = element.getBoundingClientRect()

        const top = childPos.top - parentPos.top
        const height = childPos.bottom - childPos.top

        const midPoint = top + height / 2

        ctx.arcTo(20, midPoint, canvas.width, midPoint, curveRadius)
        ctx.lineTo(canvas.width, midPoint)
        ctx.stroke()
      }
    },
    getProductNameFromPortUid(productUid) {
      const port = this.myPorts.find(p => p.productUid === productUid)
      if (!port) {
        // This could happen where you have something defined locally as being diverse from
        // an existing port, but someone else has deleted the port that it is supposed to be
        // diverse from. We should probably update the port to not refer to the non-existent one...but what
        // if it's still loading and appears later? Anyway, this is probably a rare enough case to not
        // need to deal with it. After all, the only "damage" is that potentially the port purchase
        // request goes through specifying that it's in a specific zone when perhaps it didn't need
        // to be specified.
        return this.$t('general.unknown')
      }
      return port.productName
    },
    determineAddConnectionTooltipContent(isLag = false) {
      // Determine the product type sub-string for the translation string
      let productTypeSubstring

      if (isLag) {
        productTypeSubstring = 'lag'
      } else {
        switch (this.portLike.productType) {
          case this.G_PRODUCT_TYPE_MCR2:
            productTypeSubstring = 'mcr'
            break
          case this.G_PRODUCT_TYPE_MVE:
            productTypeSubstring = 'mve'
            break
          default:
            productTypeSubstring = 'port'
        }
      }

      // Determine the content based on product type and whether connections can be added
      if (this.canConnect) {
        return this.$t('ports.add-connection-to', { type: this.$t(`productNames.${productTypeSubstring}`) })
      } else {
        return this.$t(`connections.${productTypeSubstring}-full`)
      }
    },
    getStatusInfo(port) {
      const statusInfo = this.statusInfo.statuses.find(portStatus => portStatus.productUid === port.productUid)

      return statusInfo || {}
    },
    getServiceIcon(service) {
      if (service.productType === this.G_PRODUCT_TYPE_MVE) {
        return this.G_PRODUCT_TYPE_MVE
      }
      if (service.productType === this.G_PRODUCT_TYPE_MCR2) {
        return 'MCR'
      }
      if (service.lagPrimary || service.lagPortCount) {
        return 'LAG'
      }
      return this.G_PRODUCT_TYPE_MEGAPORT
    },
    getWhitelabelClass(service) {
      return service.productType === this.G_PRODUCT_TYPE_MEGAPORT
        ? `wl-port-color`
        : `wl-mcr-color`
    },
    showDetails(service) {
      if (service.productType === this.G_PRODUCT_TYPE_MVE) {
        closeTooltipsAndNavigateToRoute(this.$router, `/mve/${service.productUid}`)
      } else if (service.provisioningStatus === this.G_PROVISIONING_DESIGN) {
        const serviceTypeUrlParam = this.isPort ? 'port' : 'mcr'
        closeTooltipsAndNavigateToRoute(this.$router, `/create-megaport/${serviceTypeUrlParam}/${service.productUid}`)
      } else {
        closeTooltipsAndNavigateToRoute(this.$router, `/edit-megaport/${service.productUid}`)
      }
      this.trackButtonClick(service.provisioningStatus === this.G_PROVISIONING_DESIGN ? 'edit-config' : 'edit')
    },
    calculatePortSpeedText(port) {
      if (port.lagPortCount) {
        return convertSpeed(port._aggSpeed)
      } else {
        return convertSpeed(port.speed || port.portSpeed)
      }
    },
    determineDetailsTooltipContent(service) {
      // Determine the product type sub-string for the translation string
      let productTypeSubstring

      switch (service.productType) {
        case this.G_PRODUCT_TYPE_MCR2:
          productTypeSubstring = 'mcr'
          break
        case this.G_PRODUCT_TYPE_MVE:
          productTypeSubstring = 'mve'
          break
        default:
          productTypeSubstring = service.lagPrimary || service.lagPortCount ? 'lag' : 'port'
      }

      // Determine the content based on product type
      return this.$t('general.type-details', { type: this.$t(`productNames.${productTypeSubstring}`) })
    },
    determineLockTooltipContent(service) {
      // Determine the translation sub-string based on product type
      let productTypeString

      switch (service.productType) {
        case this.G_PRODUCT_TYPE_MCR2:
          productTypeString = 'productNames.mcr'
          break
        case this.G_PRODUCT_TYPE_MVE:
          productTypeString = 'productNames.mve'
          break
        default:
          productTypeString = service.lagPrimary || service.lagPortCount ? 'productNames.lag' : 'productNames.port'
      }

      // If the service is adminLocked, no one can unlock it, not even admin users.
      // This means that Megaport itself has locked the service.
      if (service.adminLocked) {
        return this.$t('services.type-locked-megaport', { type: this.$t(productTypeString) })
      }

      // If the service is just 'locked', only admin users can unlock it.
      // This means that an admin user has locked the service.
      if (service.locked) {
        if (this.isAdmin) {
          // If an admin user, determine the content based on whether the service is live
          // since only services that are LIVE can be unlocked.
          if (service.provisioningStatus === this.G_PROVISIONING_LIVE) {
            return this.$t('services.type-unlock', { type: this.$t(productTypeString) })

          } else {
            return this.$t('services.type-unlock-notlive', { type: this.$t(productTypeString) })
          }

        } else {
          // If not an admin user, the service cannot be unlocked, so return the relevant content.
          return this.$t('services.type-locked-admin', { type: this.$t(productTypeString) })
        }

      } else if (this.isAdmin) {
        // If an admin user and the service is unlocked, determine the content based on whether the service is live
        // since only services that are LIVE can be locked.
        if (service.provisioningStatus === this.G_PROVISIONING_LIVE) {
          return this.$t('services.type-lock', { type: this.$t(productTypeString) })

        } else {
          return this.$t('services.type-lock-notlive', { type: this.$t(productTypeString) })
        }

      } else {
        // If not an admin user, the service cannot be locked, so return the relevant content.
        return this.$t('services.type-unlocked-admin', { type: this.$t(productTypeString) })
      }
    },
    getDeleteButtonData(service) {
      let productTypeString

      switch (service.productType) {
        case this.G_PRODUCT_TYPE_MCR2:
          productTypeString = 'productNames.mcr'
          break
        case this.G_PRODUCT_TYPE_MVE:
          productTypeString = 'productNames.mve'
          break
        default:
          productTypeString = 'productNames.port'
      }

      if (service.locked || service.adminLocked) {
        return {
          canDelete: false,
          message: this.$t('services.type-locked', { type: this.$t(productTypeString) }),
        }
      }

      // This can happen if a service is decommissioned afer the services have been loaded
      // and has not yet been cleaned up during periodic updates.
      if (service.provisioningStatus === this.G_PROVISIONING_DECOMMISSIONED) {
        return {
          canDelete: false,
          message: this.$t('services.decommissioned'),
        }
      }

      // LAG primary cannot be deleted if any of its subLag ports are locked
      if (service.lagPrimary && service._subLags && service.provisioningStatus !== this.G_PROVISIONING_DESIGN) {
        for (const lag of service._subLags) {
          if (lag.locked || lag.adminLocked) {
            return {
              canDelete: false,
              message: this.$t('ports.lag-primary-deletion-locked'),
            }
          }
        }
      }

      if (service.provisioningStatus === this.G_PROVISIONING_LOADING) {
        return {
          canDelete: false,
          message: this.$t('general.loading-data'),
        }
      }

      if ([this.G_PROVISIONING_CANCELLED, this.G_PROVISIONING_CANCELLED_PARENT].includes(service.provisioningStatus)) {
        return {
          canDelete: false,
          message: this.$t('services.type-cancelled', { type: this.$t(productTypeString) }),
        }
      }

      if (service.provisioningStatus === this.G_PROVISIONING_DESIGN_DEPLOY) {
        return {
          canDelete: false,
          message: this.$t('services.type-being-deployed', { type: this.$t(productTypeString) }),
        }
      }

      // It's OK to delete a service in design so long as it is not a port being added to a LAG,
      // and removal of the port leaves the LAG with sufficient capacity for the connections.

      // Either a port, MVE, MCR, or a brand new LAG being designed.
      if (service.provisioningStatus === this.G_PROVISIONING_DESIGN && !service.aggregationId) {
        // If the service has the lagPortCount property, it's an in-design port part of a LAG.
        // Otherwise, it is a standalone port, MVE, or MCR as defined above.
        if (service.lagPortCount) {
          productTypeString = 'productNames.lag'
        }
        return {
          canDelete: true,
          message: this.$t('general.delete-type', { type: this.$t(productTypeString) }),
        }
      }

      // Either a not-in-design port part of a not-in-design LAG,
      // or an in-design LAG linked to a not-in-design LAG.
      if (service.aggregationId && !service.lagPrimary) {
        const lagPrimary = this.myPorts.find(aPort => {
          return aPort.lagPrimary && aPort.aggregationId === parseInt(service.aggregationId)
        })

        // The total number of ports excludes cancelled ones and is as follows:
        // Total = primary LAG + not-in-design ports + lagPortCount of extra in-design ports being added
        let allPorts = 0
        // Count the primary LAG port if not cancelled
        if (lagPrimary.provisioningStatus !== this.G_PROVISIONING_CANCELLED) allPorts++

        for (const currentSubLag of lagPrimary._subLags) {
          const subLagisNotCancelled = currentSubLag.provisioningStatus !== this.G_PROVISIONING_CANCELLED
          const subLagisInDesign = currentSubLag.provisioningStatus === this.G_PROVISIONING_DESIGN
          // Only count ports that have not been cancelled
          if (subLagisNotCancelled) {
            // If in design, count all extra LAG ports, and if not, just count the port.
            subLagisInDesign ? allPorts += currentSubLag.lagPortCount : allPorts++
          }
        }

        // If the port to be removed is not in design, decrease the counter by one.
        // Otherwise, decrease the counter by the lagPortCount of the ports in design.
        let remainingPorts = allPorts
        service.provisioningStatus === this.G_PROVISIONING_DESIGN ? remainingPorts -= service.lagPortCount : remainingPorts--

        // Calculate the remaining speed knowing that all ports in a LAG share the same speed
        const remainingSpeed = remainingPorts * lagPrimary.speed
        // We need to be able to support the speed of the fastest connection
        const allSpeeds = [].concat(...lagPrimary.associatedIxs.map(ix => ix.rateLimit), ...lagPrimary.associatedVxcs.map(vxc => vxc.rateLimit))
        const requiredSpeed = Math.max(...allSpeeds)

        if (remainingSpeed < requiredSpeed) {
          return {
            canDelete: false,
            message: this.$t('ports.deletion-insufficient-capacity'),
          }
        }

        // If it's in design, we know it can be deleted.
        // There's no need to check permissions.
        if (service.provisioningStatus === this.G_PROVISIONING_DESIGN) {
          const type = this.$tc('ports.pluralize-ports', service.lagPortCount)
          return {
            canDelete: true,
            message: this.$t('general.delete-type', { type: type }),
          }
        }

      } else {
        // It's either not a LAG port, or it's the LAG primary port
        // so we need to check if any attached services are locked.
        for (const vxc of service.associatedVxcs) {
          if (vxc.locked || vxc.adminLocked) {
            return {
              canDelete: false,
              message: this.$t('ports.attached-vxc-locked'),
            }
          }
        }

        for (const ix of service.associatedIxs) {
          if (ix.locked || ix.adminLocked) {
            return {
              canDelete: false,
              message: this.$t('ports.attached-ix-locked'),
            }
          }
        }
      }

      // If we got up to here, we're deleting something that has been deployed,
      // so we need to check that we have permission to do so.
      if (this.canPlaceOrder) {
        if (service.lagPrimary) {
          productTypeString = 'productNames.lag'
        }

        return {
          canDelete: true,
          message: this.$t('general.delete-type', { type: this.$t(productTypeString) }),
        }
      } else {
        return {
          canDelete: false,
          message: this.$t('general.delete-permission-denied', { type: this.$t(productTypeString) }),
        }
      }
    },
    cancelService(service) {
      this.$emit('cancelService', service.productUid)
      this.trackButtonClick(service.provisioningStatus === this.G_PROVISIONING_DESIGN ? 'delete-config' : 'delete')
    },
    checkIfLoading(port) {
      return this.loadingUid === port.productUid
    },
    lockPort(port) {
      this.loadingUid = port.productUid
      sdk.instance
        .product(port.productUid)
        .lock()
        .then(() => {
          this.loadingUid = null
          this.setLockedState({ obj: port, locked: true })
        })
        .catch(e => {
          this.loadingUid = null
          captureSentryError(e)
        })
      this.trackButtonClick('lock')
    },
    unlockPort(port) {
      this.loadingUid = port.productUid
      sdk.instance
        .product(port.productUid)
        .unlock()
        .then(() => {
          this.loadingUid = null
          this.setLockedState({ obj: port, locked: false })
        })
        .catch(e => {
          this.loadingUid = null
          captureSentryError(e)
        })
      this.trackButtonClick('unlock')
    },
    navigateToUid(productUid) {
      this.$emit('navigateToUid', productUid)
    },
    navigateToIx(networkServiceType) {
      this.$emit('navigateToIx', networkServiceType)
    },
    trackButtonClick(buttonName) {
      captureEvent(`dashboard.map.${productTypeToEvent(this.portLike.productType)}.${buttonName}.click`)
    },
    trackedNavigation(buttonName, route) {
      closeTooltipsAndNavigateToRoute(this.$router, route)
      this.trackButtonClick(buttonName)
    },
  },
})
</script>

<style lang="scss" scoped>
.scrollable-popup {
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  overflow-y: auto;
}

.port-items {
  border: 1px solid #dcdfe6;
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: 0;
  background-color: white;
}
.port-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .port-main-content {
    flex-grow: 1;
  }
}

.details-line {
  font-size: 1.3rem;
  line-height: 18px;
  color: var(--color-text-regular);
}
.lag-separator:not(:last-child) {
  border-bottom: 1px solid #dcdfe6;
  padding-bottom: 0.5rem;
  margin: 0.5rem 0;
  &.lag-header {
    margin-top: 0;
  }
}
.stack-item {
  border: 1px solid #dcdfe6;
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
}

.stack {
  width: 100%;
}
.stack .stack-item {
  margin: 0.5rem 0;
}

.connections {
  display: flex;
  flex-direction: row;
}

.lag-buttons {
  button.el-button {
    margin: 0 3px 5px 3px;
    padding: 0 1.5rem;
    height: 40px;
    i {
      font-size: 1.8rem;
    }
  }
  .is-disabled i {
    color: var(--color-text-regular-disabled);
  }
}

button.el-button {
  padding: 0;
  height: 40px;
  &.add-connection {
    width: 138px;
    .connection-text {
      font-size: 1.4rem;
    }
  }
  i {
    font-size: 1.8rem;
  }
}

.service-status-icon {
  border-radius: 50%;
  background-color: var(--color-text-regular);
  width: 1.4rem;
  height: 1.4rem;
  &.all-go {
    background-color: var(--color-success);
  }
  &.partial-success {
    background-color: var(--color-warning);
  }
  &.all-fail {
    background-color: var(--color-danger);
  }
}

svg.product-icon {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  fill: currentColor;
  line-height: 0;
  &.all-go {
    color: var(--color-success);
  }
  &.partial-success {
    color: var(--color-warning);
  }
  &.all-fail {
    color: var(--color-danger);
  }
}

.action-block {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  span {
    align-self: center;
  }
  button.el-button {
    padding: 0;
    width: 44px;
    height: 40px;
  }
}
</style>
